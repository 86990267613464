import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ToastrService } from 'ngx-toastr';
var timezonesjson = require('../../../../assets/timezones.json');

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  timezoneForm: FormGroup;
  counties = [];
  utcOffsetStr = '';
  selectedCountry;
  editMode = false;
  timeZoneId;
  country: any;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpService,
    private loaderService: LoaderService,
    private toast: ToastrService) { }

  ngOnInit() {
    this.buildForm();
    this.getData();
  }

  buildForm() {
    this.timezoneForm = this.formBuilder.group({
      country: ['', [Validators.required]],
    });
  }

  getData() {
    this.counties = timezonesjson;
    console.log("countries= ", this.counties);
    this.http.get('api/organization/timezone').subscribe(res => {
      console.log("this is res", res);
      this.loaderService.isLoading.next(false);
      if (res['status'] === 200) {
        this.editMode = true;
        this.timeZoneId = res['data']._id;
        this.selectedCountry = res['data'].name;
        this.country = res['data'].name;
        this.utcOffsetStr = res['data'].timeZone;
      }
    }, err => {
      this.toast.error('Something went wrong');
      this.loaderService.isLoading.next(false);
    });
  }

  countryChanged(name) {
    this.country = name;
    this.utcOffsetStr = name.substr(4, 6);
    console.log(this.utcOffsetStr);
  }

  saveChange() {
    if (this.editMode) {
      const data = {
        name: this.country,
        timeZone: this.utcOffsetStr,
        _id: this.timeZoneId
      };
      this.loaderService.isLoading.next(true);
      this.http.put('api/admin/organization/timezone', data).subscribe(res => {
        console.log(res);
        this.loaderService.isLoading.next(false);
        if (res['status'] === 200) {
          this.toast.success('TimeZome updated successfully');
        } else {
          this.toast.error(res['message']);
        }
      }, err => {
        this.toast.error('Something went wrong');
        this.loaderService.isLoading.next(false);
      });
    } else {
      const data = {
        name: this.country,
        timeZone: this.utcOffsetStr,
      };
      this.loaderService.isLoading.next(true);
      this.http.post('api/admin/organization/timezone', data).subscribe(res => {
        console.log(res);
        this.loaderService.isLoading.next(false);
        if (res['status'] === 200) {
          this.toast.success('TimeZome updated successfully');
        } else {
          this.toast.error(res['message']);
        }
      }, err => {
        this.toast.error('Something went wrong');
        this.loaderService.isLoading.next(false);
      });
    }
  }

}
