import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreateAppointmentComponent } from '../create-appointment/create-appointment.component';

@Component({
  selector: 'app-doctors-online-list',
  templateUrl: './doctors-online-list.component.html',
  styleUrls: ['./doctors-online-list.component.css']
})
export class DoctorsOnlineListComponent implements OnInit {
  doctorsList: any[];
  filteredDoctorsList: any[];
  searchQuery = '';
  @Output() dialogClosed = new EventEmitter();

  constructor(
    private http: HttpService,
    private toast: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getDoctorsList();
  }

  getDoctorsList() {
    this.doctorsList = [];
    this.http.get('api/doctors').subscribe((res: any) => {
      // console.log(res);
      this.doctorsList = res.data;
      this.filteredDoctorsList = this.doctorsList;
      // console.log('doctors Online List: ', res.data);
    });
  }

  searchDoctors(value) {
    console.log(value);
    const filterValue = value.toLowerCase();
    this.filteredDoctorsList = this.doctorsList.filter(option => option.fullName.toLowerCase().includes(filterValue) || option.specialization.toLowerCase().includes(filterValue));
    console.log(this.filteredDoctorsList);
  }

  createAppoinment(doctor) {
    console.log(doctor);
    const dialogRef = this.dialog.open(CreateAppointmentComponent, {
      autoFocus: false,
      data: {
        doctor: doctor
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.dialogClosed.emit();
    });
  }

}
