import { Component, OnInit } from "@angular/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import { OptionsInput, EventInput } from "@fullcalendar/core";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpService } from "src/app/core/services/http/http.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
  selector: "app-doctor-profile",
  templateUrl: "./doctor-profile.component.html",
  styleUrls: ["./doctor-profile.component.css"],
})
export class DoctorProfileComponent implements OnInit {
  editMode = false;
  DocId;
  options: OptionsInput;
  user: any;
  doctorProfileForm: FormGroup;
  imageUrl: string;
  slots: any[] = [];
  doctor: any;
  selectedDate: Date;
  regularDistribution = 100 / 3;
  urlCheck = false;
  selectedSlot: any[] = [];
  allSlots: any[] = [];
  slotsByDate: any[] = [];
  bookedSlots: any[] = [];
  calendarEvents: EventInput[] = [];
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  resource: any;
  weekDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  todaysDate = moment().format();
  day = moment().format("dddd").toUpperCase();
  slotList: string[] = ["20", "30", "40", "60"];
  selectedDay = "";
  startTime: any;
  endTime: any;
  slotDuration;
  editSlotMode = false;
  email;
  roomName;
  leavesData = [];
  leaveForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      this.user = user["userDetails"];
    } else {
      this.http.get("api/user/token").subscribe((response: any) => {
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }
  }

  print() {
    console.log(
      moment(this.startTime).format("HH:mm"),
      moment(this.endTime).format("HH:mm")
    );
  }

  ngOnInit() {
    const url = this.router.url.split("/");
    if (url[url.length - 1] === "manage-calendar") {
      this.urlCheck = true;
    } else {
      this.urlCheck = false;
    }
    this.selectedDate = new Date();
    this.doctorProfile();
    this.leaveFormvalidation();
    this.options = {
      header: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
    };

    this.route.params.subscribe((params) => {
      if (params["_id"] !== undefined && params["_id"] !== null) {
        this.editMode = true;
        this.doctorProfileForm.controls["email"].disable();
        this.doctorProfileForm.controls["roomName"].disable();
        this.DocId = params["_id"];
        this.http.get("api/user/profile/" + this.DocId).subscribe((res) => {
          if (res["status"] === 200) {
            console.log(res);

            this.resource = res["data"];
            this.email = res["data"].userId.email;
            this.roomName = res["data"].userId.roomName;
            this.imageUrl = res["data"].userId.imageUrl;
            this.doctorProfileForm.patchValue({
              email: res["data"].userId.email,
              mobileNo: res["data"].userId.mobileNo,
              fullName: res["data"].userId.fullName,
              gender: res["data"].gender,
              dob: res["data"].userId.dob,
              address: res["data"].address,
              location: res["data"].location,
              city: res["data"].city,
              state: res["data"].state,
              zipCode: res["data"].zipCode,
              // fees: res['data'].fees,
              specialization: res["data"].specialization,
              licenceNo: res["data"].licenceNo,
              education: res["data"].education,
              roomName: res["data"].userId.roomName,
            });
          }
        });
        this.getDoctorSlots(this.DocId);
        this.getLeaveData(this.DocId);
      }
    });
  }

  leaveFormvalidation() {
    this.leaveForm = this.fb.group({
      leaveDate: ["", [Validators.required]],
    });
  }

  doctorProfile() {
    this.doctorProfileForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
        ],
      ],
      mobileNo: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      fullName: ["", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
      gender: ["", Validators.required],
      dob: ["", [Validators.required]],
      address: ["", Validators.required],
      location: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zipCode: ["", Validators.required],
      // fees: ['', [Validators.required]],
      specialization: ["", Validators.required],
      licenceNo: ["", Validators.required],
      education: ["", Validators.required],
      roomName: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")],
      ],
    });
  }

  addLeave() {
    if (this.leaveForm.valid) {
      this.loaderService.isLoading.next(true);
      const data = {
        doctorId: this.resource.userId._id,
        date: moment(this.leaveForm.value.leaveDate).format("YYYY-MM-DD"),
      };
      this.http.post("api/admin/doctors/leaves", data).subscribe(
        (res: any) => {
          this.loaderService.isLoading.next(false);
          if (res.status === 200) {
            this.toast.success(res.message);
            this.getLeaveData(this.DocId);
          } else {
            this.toast.error("Something went wrong");
          }
        },
        (err) => {
          this.loaderService.isLoading.next(false);
          this.toast.error(err);
        }
      );
    }
  }

  editStatus(d) {
    this.loaderService.isLoading.next(true);
    const data = {
      _id: d._id,
      doctorId: this.resource.userId._id,
      date: moment(d.date).format("YYYY-MM-DD"),
      status: "CANCELLED"
    };
    this.http.put("api/admin/doctors/leaves", data).subscribe((res: any) => {
      this.loaderService.isLoading.next(false);
      if (res.status === 200) {
        this.toast.success(res.message);
        this.getLeaveData(this.DocId);
      } else {
        this.toast.error("Something went wrong");
      }
    },
      (err) => {
        this.loaderService.isLoading.next(false);
        this.toast.error(err);
      });
  }

  addDoctor() {
    if (this.doctorProfileForm.valid) {
      if (this.editMode) {
        this.doctorProfileForm.value.userId = this.DocId;
        this.doctorProfileForm.value.email = this.email;
        this.doctorProfileForm.value.roomName = this.roomName;
      }
      console.log(this.doctorProfileForm.value);
      this.loaderService.isLoading.next(true);
      this.doctorProfileForm.value.dob = moment(
        this.doctorProfileForm.value.dob
      ).format("YYYY-MM-DD");
      this.http
        .post("api/admin/addDoctor", this.doctorProfileForm.value)
        .subscribe(
          (response: any) => {
            this.loaderService.isLoading.next(false);
            if (response.status === 200) {
              if (!this.editMode) {
                this.toast.success(response.message, "", { timeOut: 3000 });
              } else {
                this.toast.success(
                  "Doctor information updated successfully",
                  "",
                  { timeOut: 3000 }
                );
              }
              this.router.navigate(["/app/dashboard/doctors"]);
            } else {
              this.toast.error(response.message, "", { timeOut: 3000 });
            }
          },
          (error) => {
            this.loaderService.isLoading.next(false);
            this.toast.error(error);
          }
        );
    }
  }

  // selectDay(selectDay) {
  //   this.selectedDay = selectDay;
  //   console.log(this.selectedDay);
  // }

  validTime(time: string) {
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) === null) {
      return false;
    } else {
      return true;
    }
  }

  selectSlotDuration(duration) {
    console.log(duration.value);
    this.selectedStartTime();
  }

  selectedStartTime() {
    console.log(this.startTime);
  }

  onChangeDate(event) {
    this.selectedDate = event.date;
    this.slots = [];
    this.selectedSlot = [];
    this.getDoctorSlots(this.resource.userId._id);
  }

  getDoctorSlots(doctorId) {
    if (!doctorId) {
      return this.toast.warning("Resource id not found!!", "", {
        timeOut: 3000,
      });
    }
    this.loaderService.isLoading.next(true);
    this.slots = [];
    this.http
      .get(`api/admin/getdoctorSlotByDay/${doctorId}?day=${this.day}`)
      .subscribe(
        (response: any) => {
          this.loaderService.isLoading.next(false);
          if (response.status === 200) {
            this.slots = response.data;
            if (this.slots.length > 0) {
              this.editSlotMode = false;
            }
          }
        },
        (err) => {
          this.loaderService.isLoading.next(false);
          this.toast.error("Something went wrong");
        }
      );
  }

  getLeaveData(doctorId) {
    this.http.get("api/admin/doctors/leaves/" + doctorId).subscribe((res: any) => {
      this.leavesData = res.data;
      console.log("data is = ", this.leavesData);
    });
  }

  deleteSlot(slotIid) {
    this.http.delete("api/admin/slots/" + slotIid).subscribe((res) => {
      console.log(res);
      this.getDoctorSlots(this.DocId);
    });
  }

  eventClicked(calDate) {
    console.log(calDate);
  }

  check(id) {
    if (this.selectedSlot.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  reset() {
    this.selectedSlot = [];
    this.slots = this.slotsByDate;
  }

  resetForm() {
    this.doctorProfileForm.reset();
  }

  UpdateSlots() {
    let startTime = moment(this.startTime).format("HH:mm");
    let endTime = moment(this.endTime).format("HH:mm");
    if (!this.resource) {
      return this.toast.warning("Resource id not found!!", "", {
        timeOut: 2000,
      });
    }

    if (!startTime) {
      return this.toast.warning("Please Select Start Time!!", "", {
        timeOut: 2000,
      });
    } else if (!this.validTime(startTime)) {
      return this.toast.warning(
        "Please enter valid start time in HH:mm format",
        "",
        { timeOut: 2000 }
      );
    } else if (!endTime) {
      return this.toast.warning("Please Select End Time!!", "", {
        timeOut: 2000,
      });
    } else if (!this.validTime(endTime)) {
      return this.toast.warning(
        "Please enter valid end time in HH:mm format",
        "",
        { timeOut: 2000 }
      );
    } else if (!this.slotDuration) {
      return this.toast.warning("Please Select Slot Duration!!", "", {
        timeOut: 2000,
      });
    } else if (!this.selectedDay) {
      return this.toast.warning("Please Select Day!!", "", { timeOut: 2000 });
    } else if (startTime >= endTime) {
      return this.toast.warning(
        "Start Time should be less than End Time!!",
        "",
        { timeOut: 2000 }
      );
    }

    const data = {
      dayName: this.selectedDay,
      doctorId: this.resource.userId._id,
      startTime: startTime,
      endTime: endTime,
      interval: this.slotDuration,
    };
    console.log("data=", data);
    // return;
    this.loaderService.isLoading.next(true);
    this.http.put("api/admin/updateDynamicSlots", data).subscribe(
      (response: any) => {
        this.loaderService.isLoading.next(false);
        if (response.status === 200) {
          this.selectedSlot = [];
          this.toast.success(response.message);
          this.getDoctorSlots(this.resource.userId._id);
        }
      },
      (error) => {
        this.loaderService.isLoading.next(false);
        this.toast.error(error);
      }
    );
  }

  dobChanged(event) {
    const dob = moment(event.value).format("YYYY-MM-DD");
    this.doctorProfileForm.patchValue({ dob });
  }

  gotoDashboard() {
    this.router.navigate(["/app/dashboard"]);
  }

  goToDoctorsListing() {
    this.router.navigate(["/app/dashboard/doctors"]);
  }

  gotoIntakeForms() {
    this.router.navigate(["/app/dashboard/intake-forms"]);
  }
  logout() {
    this.authService.logout();
  }
}
