import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    flag = false;
    constructor(
        private authenticationService: AuthenticationService,
        private toast: ToastrService
    ) {
        this.flag = false;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
                if (!this.flag) {
                    this.flag = true;
                    this.toast.warning('Session expired. Please login again');
                }
                this.authenticationService.logout();
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}