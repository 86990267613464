import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openVideo(value){
    if(value === 'doc'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/videos/Doctor+traning+video_x264.mp4');
    }
    else if(value === 'pat'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/videos/Pathway_Patient_x264.mp4');
    }
    else if(value === 'nur'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/videos/Nurse+Training+Video_x264.mp4');
    }
  }

  openPdf(value){
    if(value === 'doc'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/pdf/User+Guide+for+Doctor.pdf');
    }
    else if(value === 'pat'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/pdf/User+Guide+for+Patients.pdf');
    }
    else if(value === 'nur'){
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/pdf/User+Guide+for+Nurse.pdf');
    }
    else{
      window.open('https://pathway-healthcare-help.s3-us-west-1.amazonaws.com/pdf/Usage+Guide+for+Admin.pdf');
    }
  }

}
